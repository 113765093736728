<template>
  <div class="left-space-desktop">
    <div class="page-content w-100">
      <!-- start heading -->
      <div class="row">
        <div class="col-sm-12">
          <p class="text-center page-heading">Profile and settings</p>
        </div>
      </div>
      <!-- end heading -->
      <div class="row mt-2 ">
        <div class="col-sm-12 settingsStyle">
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <button class="nav-link " id="nav-aboutme-tab" data-bs-toggle="tab" data-bs-target="#nav-aboutme"
                type="button" role="tab" aria-controls="nav-aboutme" aria-selected="true">About me</button>

              <button class="nav-link" id="nav-practice-tab" data-bs-toggle="tab" data-bs-target="#nav-practice"
                type="button" role="tab" aria-controls="nav-practice" aria-selected="false" v-for="tabs in button"
                :key="tabs">My practice</button>

              <button class="nav-link" id="nav-practice" data-bs-toggle="tab" data-bs-target="#nav-practice" type="button"
                role="tab" aria-controls="nav-practice" aria-selected="false" v-for="practice in zero" :key="practice"
                @click="statuszero()">My practice</button>

              <button class="nav-link" id="nav-practice" data-bs-toggle="tab" data-bs-target="#nav-practice" type="button"
                role="tab" aria-controls="nav-practice" aria-selected="false" v-for="mypractice in rejected"
                :key="mypractice" @click="statusrejected()">My practice</button>

              <button class="nav-link" id="nav-practice" data-bs-toggle="tab" data-bs-target="#nav-practice" type="button"
                role="tab" aria-controls="nav-practice" aria-selected="false" v-for="myypractice in pending"
                :key="myypractice" @click="statuspending()">My practice</button>


              <!-- <button class="nav-link" id="nav-advancedsettings-tab" v-show="!show" @click="navbar" data-bs-toggle="tab"
                data-bs-target="#nav-advancedsettings" type="button" role="tab" aria-controls="nav-advancedsettings"
                aria-selected="false" v-for="tabs in button" :key="tabs">Advanced settings</button>
  
                <button class="nav-link" id="nav-advancedsettings-tab" v-show="!show" @click="navbar,statuszero()" data-bs-toggle="tab"
                data-bs-target="#nav-advancedsettings" type="button" role="tab" aria-controls="nav-advancedsettings"
                aria-selected="false"  v-for="practice in zero" :key="practice">Advanced settings</button>
  
                <button class="nav-link" id="nav-advancedsettings-tab" v-show="!show" @click="navbar,statusrejected()" data-bs-toggle="tab"
                data-bs-target="#nav-advancedsettings" type="button" role="tab" aria-controls="nav-advancedsettings"
                aria-selected="false"  v-for="mypractice in rejected" :key="mypractice">Advanced settings</button>
  
                <button class="nav-link" id="nav-advancedsettings-tab" v-show="!show" @click="navbar,statuspending()" data-bs-toggle="tab"
                data-bs-target="#nav-advancedsettings" type="button" role="tab" aria-controls="nav-advancedsettings"
                aria-selected="false"  v-for="myypractice in pending" :key="myypractice">Advanced settings</button> -->

              <!-- <button class="nav-link disabled" id="nav-team-tab" v-show="show" data-bs-toggle="tab" data-bs-target="#nav-team"
                type="button" role="tab" aria-controls="nav-team" aria-selected="false">Team</button> -->

              <button class="nav-link active" id="nav-groups-tab" data-bs-toggle="tab" data-bs-target="#nav-groups"
                type="button" role="tab" aria-controls="nav-groups" aria-selected="true">Group</button>

              <button class="nav-link" id="nav-messaging-tab" data-bs-toggle="tab" data-bs-target="#nav-messaging"
                type="button" role="tab" aria-controls="nav-messaging" aria-selected="false">Messaging</button>

              <!-- <button class="nav-link disabled" id="nav-payments-tab" v-show="show" data-bs-toggle="tab"
                data-bs-target="#nav-payments" type="button" role="tab" aria-controls="nav-payments"
                aria-selected="false">Payment</button> -->
            </div>
          </nav>
          <div class="text-border"></div>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade " id="nav-aboutme" role="tabpanel" aria-labelledby="nav-aboutme-tab">
              <about-me></about-me>
            </div>
            <div class="tab-pane fade" id="nav-practice" role="tabpanel" aria-labelledby="nav-practice-tab">
              <practice></practice>
            </div>
            <div class="tab-pane fade" id="nav-team" role="tabpanel" aria-labelledby="nav-team-tab">
              <BankDetails></BankDetails>
            </div>
            <div class="tab-pane fade show active" id="nav-groups" role="tabpanel" aria-labelledby="nav-groups-tab">
              <Groups></Groups>
            </div>
            <div class="tab-pane fade" id="nav-messaging" role="tabpanel" aria-labelledby="nav-messaging-tab">
              <InitialReference></InitialReference>
            </div>
            <div class="tab-pane fade" id="nav-payments" role="tabpanel" aria-labelledby="nav-payments-tab">
              <MessagingSetting></MessagingSetting>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AboutMe from "./aboutMe.vue"
import Practice from "./practice.vue"
import BankDetails from "./bankDetails.vue"
import Groups from "./doctorGroups.vue"
import InitialReference from "./initialReference.vue"
import MessagingSetting from "./messagingSettings.vue"
import axios from "axios";
export default {
  components: {
    AboutMe,
    Practice,
    BankDetails,
    Groups,
    InitialReference,
    MessagingSetting,
  },
  data() {
    return {
      show: false,
      button: [],
      zero: [],
      rejected: [],
      pending: [],
      currentRoute: ""
    }
  },

  methods: {
    //   navbar: function () {
    //     this.show = !this.show;
    //   },
    async statusTrue() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/hcps/${localStorage.getItem('id')}/one`)
        .then((response) => {
          if (response?.data) {
            if (response.data?.status?.status === 'Approved') {
              this.button.push(response.data?.status?.id)
            }
            else if (response.data?.status?.status === 'Rejected') {
              this.rejected.push(response.data?.status?.id)
            }
            else if (response.data?.status === null) {
              this.zero.push(response.status?.id)
            }
            else if (response.data?.status?.status === 'UnderReview') {
              this.pending.push(response)
            }
          }
        })
    },
    statuszero() {
      if (this.zero) {
        this.$swal("Please complete the About Me section of the Settings Page");
        return
      }
    },
    statusrejected() {
      if (this.rejected) {
        this.$swal("Please fix the review comments of your request for access");
        return
      }
    },
    statuspending() {
      if (this.pending) {
        this.$swal("Your request for access is under review. Please wait.");
        return
      }
    }
  },
  created: function () {
    this.statusTrue();
    console.log(this.$route.path.split('/')[2], "currentRouterPath");
  },
}
</script>
<style>
.col-sm-12.settingsStyle {
  margin: 0px 0px 0px 10px;
  background-color: #ffff;
  border-radius: 10px;
  padding-bottom: 15px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 2px 0 rgb(0 0 0 / 12%) !important;

}

.nav.nav-tabs {
  padding-top: 15px;
}

.left-space-desktop {
  margin-left: 20px;
}

.text-border {
  background-color: #00979e;
  height: 4px;
  margin-bottom: 20px;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-tabs .nav-link.active {
  color: white;
  background-color: #00979e;
  border-color: #dee2e6 #dee2e6 #fff;
}

@media screen and (max-width:991px) {
  .col-sm-12.settingsStyle {
    margin: auto;
    background-color: #ffff;
    border-radius: none;
    padding-bottom: 15px;
    box-shadow: none;
  }

  .nav.nav-tabs {
    padding-top: 0px;
  }

  .left-space-desktop {
    margin-left: 0px;
  }
}</style>